import Axios from 'axios';

const SANDBOX = "https://sandbox.tecfrac.com/gogo";
const PRODUCTION = 'https://api.gogodriver.app/gogo-service';
const SERVER_ADDRESS = process.env.NODE_ENV === 'production' ? SANDBOX : SANDBOX;
const API = {
  login: '/authenticate',
  deliveries_list: '/get/orders/page',
  delivery_single:'/get/delivery',
  drivers_list: '/get/drivers/web',
  users_count:'/get/new/users/count',
  profit_count:'/get/total/delivery/income',
  modify_estimated_amount:'/delivery/modify/amount',
  users_list:'/get/user/page',
  cancel_delivery:'/web/delivery/cancel',
  deliveries_count:'/get/orders/count',
  drivers_locations:'/get/drivers/locations',
  orders:'/get/orders',
  driver_deliveries:'/driver/deliveries',
  suggested_drivers: '/get/order/suggest/drivers'

};
// export var AxiosInstance = Axios.create({
//   // baseURL: 'https://some-domain.com/api/',
//   timeout: 1000,
//   headers: { token: localStorage.getItem('connectionSession') },
// });
export const getAxiosInstance = () => Axios.create({
  baseURL: `${SERVER_ADDRESS}/admin`,
  timeout: 180000,
  headers: { sessionId: localStorage.getItem('sessionId'), deviceId: localStorage.getItem('deviceId') || "", token: localStorage.getItem('connectionSession') || ""},
});
export default API;
