import { Modal, Button, Icon, Input, InputNumber, message } from "antd";
import * as React from "react";
import API, { getAxiosInstance } from "../../api/api";
export class ModifyEsimatedAmount extends React.Component<any, any> {
  state = { visible: false, value: 0, loading: false };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      loading: true
    });
    getAxiosInstance()
      .post(API.modify_estimated_amount, {
        deliveryId: this.props.delivery.id,
        amount: this.state.value
      })
      .then(response => {
        console.log(response);
        message.success("Successfully changed");
        this.props.updateData();
        this.setState({
          visible: false,
          loading: false
        });
      })
      .catch(err => {
        message.error("Something went wrong");
        console.error(err);
        this.setState({
          visible: false,
          loading: false
        });
      });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  componentDidMount = () => {
    this.setState({ value: this.props.delivery.estimatedAmount });
  };

  onChange = v => {
    this.setState({ value: v });
  };

  render() {
    const { delivery } = this.props;
    return (
      <>
        <Button size="small" type="primary" onClick={this.showModal}>
          <Icon type="edit" />
        </Button>
        <Modal
          confirmLoading={this.state.loading}
          closable={!this.state.loading}
          title="Modify Estimated Amount"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <InputNumber
            onChange={this.onChange}
            min={0}
            value={this.state.value | 0}
          />
        </Modal>
      </>
    );
  }
}
