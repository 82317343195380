import * as React from 'react';
import API, { getAxiosInstance } from '../../api/api';
import moment from 'moment';
import { DELIVERY_STATUSES } from '../../api/deliveries/deliveryBean';
import { message, Card } from 'antd';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';

export const OrdersCount = ({ from, to, title }) => {
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    React.useEffect(() => {
        getAxiosInstance()
            .post(API.deliveries_count, {
                from: from,
                to: to
            })
            .then(({ data }) => {
                if (data.status) {
                    const found = data.data.find((v) => v.deliveryStatus === DELIVERY_STATUSES.COMPLETED);
                    setCount(found ? found.deliveryCount : 0);
                }
                else {
                    if (data.dialog) {
                        message.error(`${data.dialog.title}/${data.dialog.message}`);
                    }
                }

            })
            .catch(err => {
                console.error(err);
                message.error("Something went wrong");
            });
    })

    return (
        <Card>
            <NumberInfo
                subTitle={<h3>{title}</h3>}
                total={count}
            />
        </Card>
    )
}