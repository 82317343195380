import { UserBean } from "../users/userBean";
import { DriverBean } from "../drivers/driver";
import { LocationBean } from "../locations/locationBean";

export interface DeliveryBean {
  id: string;
  user: UserBean;
  driver: DriverBean;
  fromLocation: LocationBean;
  toLocation: LocationBean;
  estimatedAmount: number;
  reached:number;
  dropped:number;
  accepted:number;
  driverLocation:LocationBean;
  type: string;
  paymentType: string;
  deliveryCharge: number;
  amount: number;
  dateCreation: string;
  dateCompleted?: string;
  dateCancel?: string;
  status: string;
}


export const DELIVERY_STATUSES={
  PENDING:1,
  ONGOING:2,
  CANCELD:4,
  COMPLETED:3,
}