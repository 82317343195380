import React from "react";
import { DeliveryBean } from "../../api/deliveries/deliveryBean";
import API, { getAxiosInstance } from "../../api/api";
import { ResponseBean } from "../../api/responseBean";
import {
  message,
  Skeleton,
  Empty,
  Tag,
  Divider,
  Button,
  Row,
  Col,
  Popover
} from "antd";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import { clearInterval } from "timers";

interface DeliverySingleProps {
  deliveryId?: string;
  path?: string;
}

interface DeliverySingleState {
  delivery?: DeliveryBean;
  loading: boolean;
}

interface TestComponentProps {
  location: any;
  title: string;
  marker?: string;
  lat: number;
  lng: number;
}
const DriverMarker: React.SFC<TestComponentProps> = ({ location, title, marker }) => (
  <Popover
    content={
      <>
        <p>Latitude: {location.latitude}</p>
        <p>Longitude: {location.longitude}</p>
      </>
    }
    title={title}
  >
    <img
      alt="map marker"
      style={{
        width: "auto",
        height: 50,
        position: "relative",
        // cursor: "pointer",
        top: "-22px",
        left: "-17px"
      }}
      src={marker ? marker : `${process.env.PUBLIC_URL}/driver-marker.png`}
    />
  </Popover>
);

const TestComponent: React.SFC<TestComponentProps> = ({ location, title,marker }) => (
  <Popover
    content={
      <>
        <p>Name: {location.formattedAddress}</p>
        <p>Latitude: {location.latitude}</p>
        <p>Longitude: {location.longitude}</p>
      </>
    }
    title={title}
  >
    <img
      alt="map marker"
      style={{
        width: 50,
        height: 50,
        position: "relative",
        // cursor: "pointer",
        top: "-36px",
        left: "-17px"
      }}
      src={marker ? marker : `${process.env.PUBLIC_URL}/marker.png`}
    />
  </Popover>
);

const formatDate = d => {
  return moment(d).format("MMMM Do YYYY, h:mm:ss a");
};

export class DeliverySingle extends React.Component<
  DeliverySingleProps,
  DeliverySingleState
  > {
  state: DeliverySingleState = {
    loading: false
  };
  interval;
  componentDidMount = () => {
    this.fetchData();
    this.interval = setInterval(() => {
      this.fetchData(false);
    }, 8000);
  };
  componentWillUnmount = () => {
    window.clearInterval(this.interval);
  };

  fetchData = (showLoader = true) => {
    const { deliveryId } = this.props;
    if (showLoader) {
      this.setState({ loading: true });
    }

    getAxiosInstance()
      .post(API.delivery_single, {
        deliveryId
      })
      .then(({ data }) => {
        if (data.status) this.setState({ delivery: data.data, loading: false });
        else {
          if (data.dialog) {
            message.error(`${data.dialog.title}/${data.dialog.message}`);
          }
        }
      })
      .catch(err => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  render() {
    const { delivery } = this.state;
    if (this.state.loading) {
      return <Skeleton paragraph={{ rows: 7, width: "100%" }} />;
    }
    if (!this.state.delivery) {
      return <Empty />;
    }
    console.log(this.state);

    const centerLat =
      (this.state.delivery.fromLocation.latitude +
        this.state.delivery.toLocation.latitude) /
      2;
    const centerLong =
      (this.state.delivery.fromLocation.longitude +
        this.state.delivery.toLocation.longitude) /
      2;
    return (
      <div>
        <Button
          onClick={() => {
            window.history.back();
          }}
          icon="left"
        >
          Back
        </Button>
        <h1>Delivery #{this.state.delivery.id} </h1>
        <Tag>{this.state.delivery.status} </Tag>
        <Tag>{this.state.delivery.type} </Tag>
        <Tag>{this.state.delivery.paymentType} </Tag>
        <Divider />
        <Row>
          <Col sm={24} md={6}>
            {this.state.delivery.driver && (
              <h3>
                Driver: {this.state.delivery.driver.firstName}{" "}
                {this.state.delivery.driver.lastName}-{" "}
                <a href={`tel:${this.state.delivery.driver.phone}`}>
                  {this.state.delivery.driver.phone}
                </a>
              </h3>
            )}
            <h3>
              Client: {this.state.delivery.user.firstName}{" "}
              {this.state.delivery.user.lastName}-{" "}
              <a href={`tel:${this.state.delivery.user.phone}`}>
                {this.state.delivery.user.phone}
              </a>
            </h3>
            <h3>Delivery Charge: {this.state.delivery.deliveryCharge}</h3>
            <h3>
              <i>Estimated</i> Buy Amount: {this.state.delivery.estimatedAmount}
            </h3>
            <h3>Buy Amount: {this.state.delivery.amount}</h3>
            <h3>From: {this.state.delivery.fromLocation.formattedAddress}</h3>
            <h3>To: {this.state.delivery.toLocation.formattedAddress}</h3>
          </Col>
          <Col sm={24} md={6}>
            <h3>Created: {formatDate(this.state.delivery.dateCreation)} </h3>
            <h3>Canceled: {formatDate(this.state.delivery.dateCancel)} </h3>
            <h3>Accpeted in : {this.state.delivery.accepted} minutes </h3>
            <h3>Reached in : {this.state.delivery.reached} minutes </h3>
            <h3>Dropped in : {this.state.delivery.dropped} minutes </h3>
          </Col>
        </Row>

        <div style={{ width: "100%", height: "500px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBGOZAMmIsGDENkLlV0X-QJemy2cDSho_Q"
            }}
            defaultCenter={{
              lat: centerLat,
              lng: centerLong
            }}
            defaultZoom={14}
          >
            <TestComponent
              title="Pickup"
              location={this.state.delivery.fromLocation}
              lat={this.state.delivery.fromLocation.latitude}
              lng={this.state.delivery.fromLocation.longitude}
            />
            <TestComponent
              title="Dropoff"
              marker={`${process.env.PUBLIC_URL}/dropoff-marker.png`}
              location={this.state.delivery.toLocation}
              lat={this.state.delivery.toLocation.latitude}
              lng={this.state.delivery.toLocation.longitude}
            />
            {this.state.delivery.driverLocation && (
              <DriverMarker
                title="Driver"
                location={this.state.delivery.driverLocation}
                lat={this.state.delivery.driverLocation.latitude}
                lng={this.state.delivery.driverLocation.longitude}
              />
            )}
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}
