import { Layout, Menu, Icon, Dropdown, Row, Col, Avatar, Button, Badge, message } from "antd";
import React from "react";
import "./layout.css";
import { Router, Link, Location, Redirect } from "@reach/router";
import SubMenu from "antd/lib/menu/SubMenu";
import { Helmet } from "react-helmet";
import { NotFound } from "./notFound";
import DeliveriesList from "../modules/deliveries/list";
import DriversList from "../modules/drivers/list";
import AllDeliveries from "../modules/all_deliveries/main";
import { DeliverySingle } from "../modules/deliveries/deliverySingle";
import { Dashboard } from "../modules/dashboard/dashboard";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import UsersList from "../modules/users/list";
import API, { getAxiosInstance } from "../api/api";
import moment from "moment";
import { DELIVERY_STATUSES } from "../api/deliveries/deliveryBean";
const CustomIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1094894_pgg9hm4j8sp.js" // generated by iconfont.cn
});
const { Header, Content, Footer, Sider } = Layout;


let AppRef: Application;
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Button
        onClick={() => {
          AppRef.props.logout();
        }}
      >
        {" "}
        <Icon type="logout" /> Logout
      </Button>
    </Menu.Item>
  </Menu>
);

interface ApplicationProps {
  logout: Function;
}
interface ApplicationState {
  collapsed: boolean;
  counts: any;
  loading: boolean;
}
class Application extends React.Component<ApplicationProps, ApplicationState> {
   timeout;
  state = {
    collapsed: true,
    counts: null,
    loading: false
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  fetchCounts = () => {
    this.setState({ loading: true });
    getAxiosInstance()
      .post(API.deliveries_count, {
        from: moment().startOf("day").toDate(),
        to: moment().endOf("day").toDate()
      })
      .then(({ data }) => {
        if (data.status) this.setState({ counts: data.data || [], loading: false });
        else {
          if (data.dialog) {
            message.error(`${data.dialog.title}/${data.dialog.message}`);
          }
        }

      })
      .catch(err => {
        console.error(err);
        message.error("Something went wrong");
      });
  }

  componentDidMount = () => {
    AppRef = this;

    this.fetchCounts();

    this.timeout=window.setInterval(()=>{
      this.fetchCounts();
    },5000);

  };
  componentWillUnmount=()=>{
    window.clearInterval(this.timeout);
  }

  getStatusCount = (status) => {
    if (this.state.counts) {
      {/*
  // @ts-ignore */}
      const obj = this.state.counts.find(v => v.deliveryStatus === status)
      if (obj) {
        return obj.deliveryCount;
      }
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Layout style={{ minHeight: "100vh" }}>
          <Location>
            {({ location }) => (
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
              >
                <div style={{ padding: 5 }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="https://www.gogodriver.app/images/logo/logo2.png"
                  />
                </div>
                <Menu
                  theme="dark"
                  defaultSelectedKeys={["/"]}
                  selectedKeys={[location.pathname]}
                  mode="inline"
                >
                  {/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
                  {localStorage.getItem('userType') == "4" ? null : <Menu.Item key="/admin/dashboard">
                    <Icon type="dashboard" theme="outlined" />
                    <span>Dashboard</span>
                    <Link to="/admin/dashboard" />
                  </Menu.Item>}
                  

                  <SubMenu
                    key="deliveries"
                    title={
                      <span>
                        <Icon type="car" /> <span>Deliveries</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/admin/deliveries/pending">
                      <Icon type="ordered-list" theme="outlined" />
                      {/*
  // @ts-ignore */}
                      <span>Pending  <Badge count={this.getStatusCount(DELIVERY_STATUSES.PENDING)} /></span>
                      <Link to="/admin/deliveries/pending" />
                    </Menu.Item>
                    <Menu.Item key="/admin/deliveries/ongoing">
                      <Icon type="ordered-list" theme="outlined" />
                      <span>Ongoing  <Badge count={this.getStatusCount(DELIVERY_STATUSES.ONGOING)} /></span>
                      <Link to="/admin/deliveries/ongoing" />
                    </Menu.Item>
                    <Menu.Item key="/admin/deliveries/completed">
                      <Icon type="ordered-list" theme="outlined" />
                      <span>Completed  <Badge count={this.getStatusCount(DELIVERY_STATUSES.COMPLETED)} /></span>
                      <Link to="/admin/deliveries/completed" />
                    </Menu.Item>
                    <Menu.Item key="/admin/deliveries/canceled">
                      <Icon type="ordered-list" theme="outlined" />
                      <span>Canceled  <Badge count={this.getStatusCount(DELIVERY_STATUSES.CANCELD)} /></span>
                      <Link to="/admin/deliveries/canceled" />
                    </Menu.Item>
                  </SubMenu>
{/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
                  {localStorage.getItem('userType') == "4" ? null : <SubMenu
                    key="drivers"
                    title={
                      <span>
                        <CustomIcon type="icon-bike" /> <span>Drivers</span>
                      </span>
                    }
                  >
                    <Menu.Item key="/admin/drivers/all">
                      <Icon type="ordered-list" theme="outlined" />
                      <span>All Drivers</span>
                      <Link to="/admin/drivers/all" />
                    </Menu.Item>
                    <Menu.Item key="/admin/drivers/vacant">
                      <Icon type="ordered-list" theme="outlined" />
                      <span>Vacant Drivers</span>
                      <Link to="/admin/drivers/vacant" />
                    </Menu.Item>
                  </SubMenu>}
                  {/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
                  {localStorage.getItem('userType') == "4" ? null : <Menu.Item key="/admin/users">
                    <Icon type="team" />
                    <span>Users</span>
                    <Link to="/admin/users" />
                  </Menu.Item>}

                  <Menu.Item key="/admin/all_deliveries">
                    <Icon type="branches" />
                    <span>Pending Deliveries</span>
                    <Link to="/admin/all_deliveries" />
                  </Menu.Item>
                </Menu>
              </Sider>
            )}
          </Location>

          <Layout>
            <Header style={{ background: "#fff" }}>
              <Row>
                <Col span={20}>{/* <Link to="../">Back</Link> */}</Col>
                <Col span={4}>
                  <Dropdown overlay={menu}>
                    <Avatar style={{ verticalAlign: "middle" }} size="large">
                      Test
                    </Avatar>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: "14px 16px" }}>
              <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
                <Router>
                  <Redirect
                    noThrow
                    from="/admin"
                    to="/admin/dashboard"
                  />
                  {/* <NotFound default /> */}
                  <DeliveriesList path="/admin/deliveries/pending" status={1} />
                  <DeliveriesList path="/admin/deliveries/ongoing" status={2} />
                  <DeliveriesList
                    path="/admin/deliveries/canceled"
                    status={4}
                  />
                  <DeliveriesList
                    path="/admin/deliveries/completed"
                    status={3}
                  />
                  <DeliverySingle path="/admin/delivery/:deliveryId" />
                  <DriversList path="/admin/drivers/all" />
                  <DriversList path="/admin/drivers/vacant" status={1} />
                  <Dashboard path="/admin/dashboard" />
                  <UsersList path="/admin/users" />
                  <AllDeliveries path="/admin/all_deliveries"/>
                  

                  {/* <Home path="/admin" /> */}
                  {/* <BooksList active path="/admin/books-active" />
              <BooksList active={false} path="/admin/books-inactive" />
              <CreateCategory path="admin/create-category" />
              <UpdateCategory path="/admin/category/:categoryId" />
              <SessionsList path="/admin/list-guests" />
              <UsersList path="/admin/list-users" />
              <BooksForm path="/admin/create-books" />
              <BookSingle path="/admin/book/:bookId" /> */}

                  {/* <CategoriesList path="/admin/list-categories" /> */}
                </Router>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              Tecfrac ©{new Date().getFullYear()} All rights reserved
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default Application;
