import {
  Table,
  Tag,
  message,
  Pagination,
  DatePicker,
  Icon,
  Divider,
  Popconfirm
} from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "@reach/router";
import API, { getAxiosInstance } from "../../api/api";
import { PaginationProps } from "antd/lib/pagination";
import { DeliveryBean } from "../../api/deliveries/deliveryBean";
import { UserBean } from "../../api/users/userBean";
import { DriverBean } from "../../api/drivers/driver";
import { LocationBean } from "../../api/locations/locationBean";
import { isMobile, isBrowser } from "react-device-detect";
import { DeliveriesCards } from "./cards";
import { ModifyEsimatedAmount } from "./modifyEstimated";
let tableRef: any = false;
const CancelDelivery = ({ children, delivery }) => {
  return (
    <Popconfirm
      title="Are you sure ?"
      onConfirm={() => {
        const handle=message.loading("Working on it...");
        getAxiosInstance()
          .post(API.cancel_delivery, {
            deliveryId: delivery.id
          })
          .then(response => {
            tableRef.fetch({ hideLoading: false });
            message.success("Completed",1);
          })
          .catch(err => {
            console.error(err);
            message.error("Something went wrong");
          }).finally(()=>{
            handle();
          });
      }}
      onCancel={() => {}}
      okText="Yes"
      cancelText="No"
    >
      <a href="#">{children}</a>
    </Popconfirm>
  );
};
const getActions = record => {
  if (record.status === "Completed") {
    return <CancelDelivery delivery={record}>Set As Testing</CancelDelivery>;
  }
  if (record.status === "Canceled") {
    return null;
  } else {
    return <CancelDelivery delivery={record}>Cancel</CancelDelivery>;
  }
};
let componentInstance;
const columns = [
  {
    title: "Id",
    dataIndex: "id",
    sorter: true,
    key: "id",
    render: (title: string, record) => (
      <Link to={`/admin/delivery/${record.id}`}>{title}</Link>
    )
  },
  {
    title: "user",
    dataIndex: "user",
    // sorter: true,
    key: "user",
    render: (user: UserBean) => (
      <span>
        {user.firstName} {user.lastName}{" "}
        <a href={`tel:${user.phone}`}>{user.phone}</a>
      </span>
      // <Link to={`/admin/book/${record.id}`}>{title}</Link>
    )
  },
  {
    title: "# Of Deliveries",
    dataIndex: "user",
    // sorter: true,
    key: "deliveryCount",
    render: (user: UserBean) => (
      <span>{user.deliveryCount}</span>
      // <Link to={`/admin/book/${record.id}`}>{title}</Link>
    )
  },
  {
    title: "Driver",
    dataIndex: "driver",
    // sorter: true,
    key: "driver",
    render: (driver: DriverBean) => {
      if (driver && driver.firstName) {
        return (
          <span>
            {driver.firstName} {driver.lastName}{" "}
            <a href={`tel:${driver.phone}`}>{driver.phone}</a>
          </span>
        );
      }
    }
  },
  {
    title: "From",
    dataIndex: "fromLocation",
    // sorter: true,
    key: "fromLocation",
    render: (from: LocationBean) => {
      if (from) {
        return <span>{from.formattedAddress}</span>;
      }
    }
  },
  {
    title: "To",
    dataIndex: "toLocation",
    // sorter: true,
    key: "toLocation",
    render: (to: LocationBean) => {
      if (to) {
        return <span>{to.formattedAddress}</span>;
      }
    }
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Payment Type",
    dataIndex: "paymentType",
    key: "paymentType"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: v => <Tag color="red">{v}</Tag>
  },
  {
    title: "DeliveryCharge",
    dataIndex: "deliveryCharge",
    key: "deliveryCharge"
  },
  {
    title: "Estimated  Purchase/Collection",
    dataIndex: "estimatedAmount",
    key: "estimatedAmount",
    render: (ammount: number, doc: any) => {
      if (doc.canModify) {
        return (
          <span>
            {ammount} <Divider type="vertical" />
            <ModifyEsimatedAmount
              updateData={() => {
                componentInstance.fetch({});
              }}
              delivery={doc}
            />{" "}
          </span>
        );
      }
      return <p>{ammount}</p>;
    }
  },
  {
    title: "Purchase Amount",
    dataIndex: "amount",
    key: "amount"
  },
  {
    title: "Accepted in",
    dataIndex: "accepted",
    key: "accepted"
  },
  // {
  //   title: "Reached in",
  //   dataIndex: "reached",
  //   key: "reached"
  // },
  // {
  //   title: "Dropped in",
  //   dataIndex: "dropped",
  //   key: "dropped"
  // },

  {
    title: "Created At",
    sorter: true,
    dataIndex: "dateCreation",
    key: "dateCreation",
    render: (date: string) => (
      <span>
        {/*
  // @ts-ignore */}
        {date ? (
          // @ts-ignore */}
          <Moment
            title={moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            fromNow
          >
            {date}
          </Moment>
        ) : (
          "-"
        )}
      </span>
    )
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => <span>{getActions(record)}</span>
  }
];

interface DeliveriesListProps {
  path: string;
  status: number;
}
interface DeliveriesListState {
  data?: [DeliveryBean];
  pagination: any;
  loading: boolean;
  fromDate: Date;
  toDate: Date;
}

class DeliveriesList extends React.PureComponent<
  DeliveriesListProps,
  DeliveriesListState
> {
  intervalId: any;
  state = {
    data: undefined,
    fromDate: moment()
      .startOf("day")
      .toDate(),
    toDate: moment()
      .endOf("day")
      .toDate(),
    pagination: { pageSize: 10, total: 0, current: 1 },
    loading: false
  };
  onChangeDate = dates => {
    this.setState(
      { fromDate: dates[0].toDate(), toDate: dates[1].toDate() },
      () => {
        this.fetch({});
      }
    );
  };

  constructor(props) {
    super(props);
    componentInstance = this;
  }

  componentDidMount() {
    tableRef = this;
    this.fetch({});
    this.intervalId = setInterval(() => {
      // if(this.state.pagination.current < 5){
      //   this.fetch({ hideLoading: true });
      // }
      this.fetch({ hideLoading: true });
    }, 2000);

    
  }
  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  componentDidUpdate = (oldProps: DeliveriesListProps) => {
    if (oldProps.status !== this.props.status) {
      this.fetch({});
    }
  };

  fetch = ({
    pageSize = isMobile ? 5000 : this.state.pagination.pageSize,
    currentPage = this.state.pagination.current,
    hideLoading = false,
    order = null,
    ascending = true
  }) => {    
    if (!hideLoading) this.setState({ loading: true });
    getAxiosInstance()
      .post(API.deliveries_list, {
        page: currentPage - 1,
        size: pageSize,
        from: this.state.fromDate,
        to: this.state.toDate,
        order,
        ascending,
        status: this.props.status
      })
      .then(response => {
        const pagination = { ...this.state.pagination };
        pagination.total = response.data.data.total;
        console.log("Pagination: ",pagination);
        this.setState({
          data: response.data.data.list,
          loading: false,
          pagination
        });
      })
      .catch(err => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      pageSize: pagination.pageSize,
      order: sorter.field,
      ascending: !(sorter.order === "descend"),
      currentPage: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };

  render() {
    const { loading, data, pagination } = this.state;
    console.log("state: ", this.state);
    return (
      <React.Fragment>
        <Helmet>
          <title>Deliveries List</title>
        </Helmet>
        <DatePicker.RangePicker
          size="small"
          format={"DD/MM/YY"}
          style={{ fontSize: 5 }}
          onChange={this.onChangeDate}
          value={[moment(this.state.fromDate), moment(this.state.toDate)]}
        />
        {isBrowser ? (
          <Table
            rowKey={(record: DeliveryBean) => record.id}
            loading={loading}
            dataSource={data}
            pagination={pagination}
            onChange={this.handleTableChange}
            columns={columns}
            scroll={{ x: 1500 }}
          />
        ) : (
          <DeliveriesCards getActions={getActions} fetch={this.fetch} loading={loading} data={data} />
        )}
      </React.Fragment>
    );
  }
}

export default DeliveriesList;
