import * as React from "react";
import { List, Card, Divider, Tag } from "antd";
import Moment from "react-moment";
import { Link } from "@reach/router";
import { ModifyEsimatedAmount } from "./modifyEstimated";

export class DeliveriesCards extends React.Component<any, any> {
  canChangeEstimated = item => {
    if (item.status === "Ongoing" || item.status === "pending") {
      return true;
    }
    return false;
  };
  state = {
    loading: false
  };
  componentDidMount = () => {
    this.props.fetch({ pageSize: 5000 });
  };
  render() {
    return (
      <List
        loading={this.props.loading}
        dataSource={this.props.data}
        renderItem={(item, index) => (
          <List.Item>
            <Card
              style={{ width: "100%" }}
              title={
                <>
                  {index + 1}-
                  <Link to={`/admin/delivery/${item.id}`}>
                    {" "}
                    Delivery #{item.id}
                  </Link>
                  <br />{" "}
                  <Moment
                    date={item.dateCreation}
                    format="DD-MM-YYYY, h:mm a"
                  />{" "}
                  <br /> <Tag color="red">{item.type}</Tag>{" "}
                  <Tag color="blue">{item.paymentType}</Tag>
                </>
              }
            >
              Customer: {item.user.firstName} {item.user.lastName} -{" "}
              <a href={`tel:${item.user.phone}`}>{item.user.phone}</a>
              <br /># Of Deliveries:{item.user.deliveryCount}
              <br />
              Driver:{" "}
              {item.driver ? (
                <>
                  {item.driver.firstName} {item.driver.lastName}-{" "}
                  <a href={`tel:${item.driver.phone}`}>{item.driver.phone}</a>
                </>
              ) : (
                "No driver"
              )}
              <br />
              Delivery Charge : {item.deliveryCharge}$
              <br />
              {item.type === "COLLECT-RETURN"
                ? `Estimated Collection`
                : `Estimated Purchase`}
              : {item.estimatedAmount}$ <br />
              Accpted In : {item.accepted}
              <br />
              Reached In : {item.reached}
              <br />
              Dropped In : {item.dropped}
              {item.canModify && (
                <ModifyEsimatedAmount
                  updateData={() => {
                    this.props.fetch({});
                  }}
                  delivery={item}
                />
              )}
              <br />
              {this.props.getActions(item)}
            </Card>
          </List.Item>
        )}
      />
    );
  }
}
