import React from 'react';
import API, { getAxiosInstance } from '../../api/api';
import { message, Spin, Empty, Card } from 'antd';
import { Bar, MiniBar } from 'ant-design-pro/lib/Charts';

interface CustomersPerDayProps {
   from:Date,
   to:Date
}
interface CustomersPerDayState {
   data?:any,
   loading:boolean
}

export class CustomersPerDay extends React.Component<CustomersPerDayProps, CustomersPerDayState> {
    state: Readonly<CustomersPerDayState> = {
       loading:true
    };
    componentDidMount = () => {
        this.setState({loading:true});
        getAxiosInstance().post(API.users_count, {
            from:this.props.from,
            to: this.props.to,
            criteria: 1

        }).then(({ data }) => {
            if (data.status) {
                this.setState({ data: data.data,loading:false });
            }
        }).catch((err) => {
            console.error(err);
            message.error('Something went wrong');
        });
    }
    render() {
      
        // return (<Empty/>)
        return (
            <Card loading={this.state.loading} title="Customers Per Day">
               {
                   this.state.data&& <Bar
                   height={250}
                   title=""
                   data={this.state.data.list.map(data => ({
                       x: `${data.day}-${data.month}-${data.year}`,
                       y: data.count
                   }))}
               />
               }
            </Card>
        )
        
    }
}