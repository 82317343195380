import { Table, Tag, message, Pagination, DatePicker } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "@reach/router";
import API, { getAxiosInstance } from "../../api/api";
import { PaginationProps } from "antd/lib/pagination";
import { DeliveryBean } from "../../api/deliveries/deliveryBean";
import { UserBean } from "../../api/users/userBean";
import { DriverBean } from "../../api/drivers/driver";
import { LocationBean } from "../../api/locations/locationBean";

const columns = [
  {
    title: "userId",
    dataIndex: "userId",
    sorter: true,
    key: "userId",
    render: (title: string) => title
    // <Link to={`/admin/book/${record.id}`}>{title}</Link>
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "phone",
    dataIndex: "phone",
    key: "phone",
    render: (phone: string) => {
      return <a href={`tel:${phone}`}>{phone}</a>;
    }
  },

  {
    title: "# of Deliveries",
    dataIndex: "deliveryCount",
    key: "deliveryCount"
  },
  {
    title: "Created At",
    sorter: true,
    dataIndex: "dateCreation",
    key: "dateCreation",
    render: (date: string) => (
      <span>
        {date ? (
          // @ts-ignore
          <Moment
            title={moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            fromNow
          >
            {date}
          </Moment>
        ) : (
          "-"
        )}
      </span>
    )
  }
];

interface UsersListProps {
  path: string;
  status?: number;
}
interface UsersListState {
  data?: [DriverBean];
  pagination: any;
  loading: boolean;
  from: Date;
  to: Date;
}

class UsersList extends React.Component<UsersListProps, UsersListState> {
  state = {
    data: undefined,
    pagination: { pageSize: 10, total: 0, current: 1 },
    loading: false,
    from: moment()
      .startOf("day")
      .toDate(),
    to: moment()
      .endOf("day")
      .toDate()
  };

  componentDidMount() {
    this.fetch({});
  }

  componentDidUpdate = (oldProps: UsersListProps) => {
    if (oldProps.status !== this.props.status) {
      this.fetch({});
    }
  };

  fetch = ({
    pageSize = 10,
    currentPage = 0,
    order = null,
    ascending = true
  }) => {
    this.setState({ loading: true });
    getAxiosInstance()
      .post(API.users_list, {
        page: currentPage - 1,
        size: pageSize,
        order,
        ascending,
        from: this.state.from,
        to: this.state.to
      })
      .then(response => {
        const pagination = { ...this.state.pagination };
        pagination.total = response.data.data.total;
        this.setState({
          data: response.data.data.list,
          loading: false,
          pagination
        });
      })
      .catch(err => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      pageSize: pagination.pageSize,
      order: sorter.field,
      ascending: !(sorter.order === "descend"),
      currentPage: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };
  onChangeDate = dates => {
    this.setState({ from: dates[0].toDate(), to: dates[1].toDate() }, () => {
      this.fetch({});
    });
  };

  render() {
    const { loading, data, pagination } = this.state;
    {/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
    if(localStorage.getItem('userType') == "4"){
      return (
          <h3 style={{color: "#758790"}}>Nothing to see here...</h3>
      )
  }else{
    return (
      <React.Fragment>
        <Helmet>
          <title>Users List</title>
        </Helmet>
        <DatePicker.RangePicker
          size="small"
          format={"DD/MM/YY"}
          style={{ fontSize: 5 }}
          onChange={this.onChangeDate}
          value={[moment(this.state.from), moment(this.state.to)]}
        />
        <Table
          scroll={{ x: 1500 }}
          rowKey={(record: DriverBean) => record.id}
          loading={loading}
          dataSource={data}
          pagination={pagination}
          onChange={this.handleTableChange}
          columns={columns}
          // dataSource={data}
        />
      </React.Fragment>
    );
  }
  }
}

export default UsersList;
