import React from 'react';
import API, { getAxiosInstance } from '../../api/api';
import { message, Spin, Empty, Card, Row, Col, Divider } from 'antd';
import { Bar, MiniBar } from 'ant-design-pro/lib/Charts';
import moment from 'moment';
import { CustomersPerDay } from './customersPerDay';
import { CustomersPerMonth } from './customersPerMonth';
import { CustomersToday } from './customersToday';
import { ProfitPerDay } from './profitPerDay';
import { ProfitPerMonth } from './profitPerMonth';
import { ProfitToday } from './profitToday';
import { OrdersCount } from './ordersCount';

interface DashboardProps {
    path: string
}
interface DashboardState {

}

export class Dashboard extends React.Component<DashboardProps, DashboardState> {


    render() {

        // return (<Empty/>)
        {/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
        if(localStorage.getItem('userType') == "4"){
            return (
                <h3 style={{color: "#758790"}}>Nothing to see here...</h3>
            )
        }else{
        return (
            <>
                <Row gutter={12} style={{ marginBottom: 10 }}>
                    <Col md={6} sm={24}>
                        <OrdersCount title="Orders Today" from={moment().startOf("day")} to={moment().endOf("day")} />
                    </Col>
                    <Col md={6} sm={24}>
                        <OrdersCount title="Orders This week" from={moment().startOf("week")} to={moment().endOf("week")} />
                    </Col>
                    <Col md={6} sm={24}>
                        <OrdersCount title="Orders This Month" from={moment().startOf("month")} to={moment().endOf("month")} />
                    </Col>
                    <Col md={6} sm={24}>
                        <OrdersCount title="Orders This Year" from={moment().startOf("year")} to={moment().endOf("year")} />
                    </Col>
                </Row>
                <Row gutter={12} style={{ marginBottom: 10 }}>
                    <Col sm={12} md={12} lg={6}>
                        <CustomersToday />
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                        <ProfitToday />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={24} md={12} >
                        <CustomersPerDay from={moment().startOf('week').hours(0).minute(0).seconds(0).toDate()} to={moment().endOf('week').endOf('day').toDate()} />
                    </Col>
                    <Col sm={24} md={12} >
                        <CustomersPerMonth from={moment().startOf('day').hours(0).minute(0).seconds(0).startOf('year').toDate()} to={moment().endOf('year').toDate()} />
                    </Col>
                    <Divider />
                    <Col sm={24} md={12} >
                        <ProfitPerDay from={moment().startOf('day').startOf('week').hours(0).minute(0).seconds(0).toDate()} to={moment().endOf('week').endOf('day').toDate()} />
                    </Col>
                    <Col sm={24} md={12} >
                        <ProfitPerMonth from={moment().startOf('day').startOf('year').hours(0).minute(0).seconds(0).toDate()} to={moment().endOf('year').endOf('day').toDate()} />
                    </Col>

                </Row>
            </>
        )
    }

    }
}