import React, { Fragment } from "react";
import GoogleMapReact from "google-map-react";
import API, { getAxiosInstance } from "../../api/api";

import { Button, Popover, Checkbox } from "antd";
import { isTemplateSpan } from "typescript";
import { Link } from "@reach/router";
import { throws } from "assert";

interface AllDeliveriesListProps {
  path: string;
}

interface LineTemplate {
  fromLatitude: number;
  fromLongitude: number;
  toLatitude: number;
  toLongitude: number;
}

interface AllDeliveriesListState {
  drivers_freelance: any;
  drivers_fixed: any;
  orders_displayed: any;
  orders_hidden: any;
  onLoadOrders: boolean;
  onLoadDrivers: boolean;
  loadFreelanceDrivers: boolean;
  loadFixedDrivers: boolean;
  map: any;
  maps: any;
  lines: any;
  onGoingDeliveries: any;
  mouseEnter: boolean;
  driverOnGoingDeliveries: any;
}

interface MapMarkerProps {
  location: any;
  title: string;
  marker?: string;
  lat: number;
  lng: number;
  clicked: Function;
  mouseEnter: Function;
  mouseLeave: Function;
}
interface DriverMarkerProps {
  location: any;
  title: string;
  marker?: string;
  lat: number;
  lng: number;
  onClick: Function;
  onHover: Function;
  onGoing: any;
  driverStatus: string;
}

const DestinationMarker: React.SFC<MapMarkerProps> = ({
  location,
  title,
  marker,
  clicked,
  mouseEnter,
  mouseLeave,
}) => (
  <span
    onClick={() => {
      clicked();
    }}
    onMouseEnter={() => {
      mouseEnter();
    }}
    onMouseLeave={() => {
      mouseLeave();
    }}
  >
    <Popover
      content={
        <>
          <p>Location: {location.formattedAddress}</p>
          <p>Client: {location.userFullName}</p>
          <p>Delivery Charge: LBP {location.deliveryCharge}</p>
          <p>Phone Number: {location.userPhone}</p>
          <Link to={`/admin/delivery/${location.id}`}>
            <button type="button">Details</button>
          </Link>
        </>
      }
      title={title}
    >
      <img
        alt="map marker"
        style={{
          width: 30,
          height: 30,
          position: "relative",
          cursor: "pointer",
          bottom: "27px",
          right: "3px",
        }}
        src={marker ? marker : `${process.env.PUBLIC_URL}/dropoff-marker.png`}
      />
    </Popover>
  </span>
);

const OrderMarker: React.SFC<MapMarkerProps> = ({
  location,
  title,
  marker,
  clicked,
  mouseEnter,
  mouseLeave,
}) => (
  <span
    onClick={() => {
      clicked();
    }}
    onMouseEnter={() => {
      mouseEnter();
    }}
    onMouseLeave={() => {
      mouseLeave();
    }}
  >
    <Popover
      content={
        <>
          <p>Location: {location.formattedAddress}</p>
          <p>Client: {location.userFullName}</p>
          <p>Delivery Charge: LBP {location.deliveryCharge} </p>
          <p>Phone Number: {location.userPhone}</p>
          <Link to={`/admin/delivery/${location.id}`}>
            <button type="button">Details</button>
          </Link>
        </>
      }
      title={title}
    >
      <img
        alt="map marker"
        style={{
          width: 30,
          height: 30,
          position: "relative",
          // cursor: "pointer",
          bottom: "27px",
          right: "16px",
        }}
        src={marker ? marker : `${process.env.PUBLIC_URL}/marker.png`}
      />
    </Popover>
  </span>
);

const DriverMarker: React.SFC<DriverMarkerProps> = ({
  location,
  title,
  marker,
  onClick,
  onHover,
  onGoing,
  driverStatus,
}) => (
  <span
    onMouseEnter={() => {
      onHover();
    }}
    onClick={() => {
      onClick();
    }}
  >
    <Popover
      content={
        <>
          <p>Phone Number: {location.info}</p>
          {onGoing.map((order, index) => {
            return (
              <div key={index}>
                <h4>On Going Delivery #{order.id}</h4>
                <div>
                  <p>From: {order.fromLocation.formattedAddress}</p>
                  <p>To: {order.toLocation.formattedAddress}</p>
                </div>
              </div>
            );
          })}
        </>
      }
      title={title}
    >
      <img
        alt="map marker"
        style={{
          width: "auto",
          height: 40,
          position: "relative",
          // cursor: "pointer",
          top: "-40px",
          left: "-12px",
        }}
        src={
          marker
            ? marker
            : `${process.env.PUBLIC_URL}/driver-marker${driverStatus}.png`
        }
      />
    </Popover>
  </span>
);

export default class AllDeliveries extends React.Component<
  AllDeliveriesListProps,
  AllDeliveriesListState
> {
  state: AllDeliveriesListState = {
    onLoadOrders: false,
    onLoadDrivers: false,
    loadFixedDrivers: true,
    loadFreelanceDrivers: true,
    orders_displayed: [],
    orders_hidden: [],
    drivers_fixed: [],
    drivers_freelance: [],
    map: null,
    maps: null,
    lines: [],
    onGoingDeliveries: [],
    mouseEnter: false,
    driverOnGoingDeliveries: [],
  };

  loadOrders = async () => {
    try {
      const res = await getAxiosInstance().post(API.orders, {
        from: null,
        to: null,
        status: [0],
      });
      const orders_displayed = res.data.data.list;
      this.setState({ orders_displayed });
      this.setState({ onLoadOrders: true });
    } catch (e) {
      console.log(e);
    }
  };

  loadDrivers = async () => {
    try {
      const resFixed = await getAxiosInstance().get(API.drivers_locations, {
        params: { fixed: true },
      });
      const resFreeLance = await getAxiosInstance().get(API.drivers_locations, {
        params: { fixed: false },
      });
      const drivers_fixed = resFixed.data.data;
      const drivers_freelance = resFreeLance.data.data;
      drivers_fixed.map((driver_fixed) => {
        driver_fixed.driverStatus = "";
      });
      drivers_freelance.map((driver_fixed) => {
        driver_fixed.driverStatus = "";
      });

      this.setState({ drivers_fixed });
      this.setState({ drivers_freelance });
      this.setState({ onLoadDrivers: true });
    } catch (e) {
      console.log(e);
    }
  };
  componentDidMount() {
    this.loadDrivers();
    this.loadOrders();
    setInterval(() => {
      this.loadDrivers;
      this.loadOrders;
    }, 60000);
  }

  loadSuggestedDrivers = async (id) => {
    try {
      this.setState({ mouseEnter: true });
      const res = await getAxiosInstance().post(API.suggested_drivers, {
        orderId: id,
      });
      const ongoing_drivers = res.data.data.suggestedOngoingDrivers;
      const vacant_drivers = res.data.data.suggestedVacantDrivers;
      const freelanceDrivers = this.state.drivers_freelance;
      const fixedDrivers = this.state.drivers_fixed;

      if (!this.state.mouseEnter) {
        return;
      }
      for (let suggested of ongoing_drivers) {
        for (let driver of fixedDrivers) {
          if (suggested.userId == driver.id) {
            driver.driverStatus = "-ongoing";
          }
        }
        for (let driver of freelanceDrivers) {
          if (suggested.userId == driver.id) {
            driver.driverStatus = "-ongoing";
          }
        }
      }
      for (let suggested of vacant_drivers) {
        for (let driver of fixedDrivers) {
          if (suggested.userId == driver.id) {
            driver.driverStatus = "-vacant";
          }
        }
        for (let driver of freelanceDrivers) {
          if (suggested.userId == driver.id) {
            driver.driverStatus = "-vacant";
          }
        }
      }

      this.setState({
        drivers_fixed: fixedDrivers,
        drivers_freelance: freelanceDrivers,
      });
    } catch (e) {
      console.log(e);
    }
  };

  resetSuggestedDrivers = () => {
    this.setState({ mouseEnter: false });
    const freelanceDrivers = this.state.drivers_freelance;
    const fixedDrivers = this.state.drivers_fixed;
    for (let driver of fixedDrivers) {
      driver.driverStatus = "";
    }
    for (let driver of freelanceDrivers) {
      driver.driverStatus = "";
    }

    this.setState({ drivers_fixed: fixedDrivers });
    this.setState({ drivers_freelance: freelanceDrivers });
  };

  drawMapLines = (map, maps,newLines) => {
    const lines = this.state.lines;
    for (let line in lines) {
      lines[line].setMap(null);
    }
    for (let drawLine of newLines) {
      const route = [] as any;
      const lines = this.state.lines;
      let fromCoord = new maps.LatLng(drawLine.fromLatitude, drawLine.fromLongitude );
      let toCoord = new maps.LatLng(drawLine.toLatitude, drawLine.toLongitude);
      route.push(fromCoord);
      route.push(toCoord);

      const line = new maps.Polyline({
        path: route,
        geodesic: true,
        strokeColor: "cornflowerblue",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      line.setMap(map);
      lines.push(line);
      this.setState({ lines });
    }
    if (this.state.orders_displayed.length < 2) {
      this.setState({
        orders_displayed: this.state.orders_hidden,
      });
    }
  };

  displayOnGoingOrders = (id) => {
    if(this.state.onGoingDeliveries.length > 0 ){
      this.setState({onGoingDeliveries: []})
    }
    getAxiosInstance()
      .post(API.driver_deliveries, {
        driverId: id,
      })
      .then((res) => {
        this.setState({
          onGoingDeliveries: res.data.data.list,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetOnGoingOrders = () => {
    this.setState({onGoingDeliveries: []})
  }

  toggleDriverOngoingDeliveires = () => {
    this.setState({driverOnGoingDeliveries: this.state.onGoingDeliveries });
    let temp=this.getOrderLines();
    temp=temp.concat(this.getDriverOrderLines());
    this.drawMapLines(this.state.map, this.state.maps,temp);
  };

  showFixedDrivers = (e) => {
    this.setState({ loadFixedDrivers: e.target.checked });
  };

  showFreelanceDrivers = (e) => {
    this.setState({ loadFreelanceDrivers: e.target.checked });
  };

  getDriverOrderLines():LineTemplate[]{
    let lines: LineTemplate[] = [];
    for (let order of this.state.onGoingDeliveries) {
      let line: LineTemplate = {
        fromLatitude: order.fromLocation.latitude,
        fromLongitude: order.fromLocation.longitude,
        toLatitude: order.toLocation.latitude,
        toLongitude: order.toLocation.longitude
      }
      lines.push(line)
    }
    return lines;
  }
  getOrderLines():LineTemplate[]{
    let lines: LineTemplate[] = [];
    for (let order of this.state.orders_displayed) {
      let line: LineTemplate = {
        fromLatitude: order.fromLatitude,
        fromLongitude: order.fromLongitude,
        toLatitude: order.toLatitude,
        toLongitude: order.toLongitude
      }
      lines.push(line)
    }
    return lines;
  }
  render() {
    if (
      this.state.onLoadOrders === false ||
      this.state.onLoadDrivers === false
    ) {
      return null;
    } else {
      return <React.Fragment>
          <div style={{ position: "relative", top: "10px", left: "10px", zIndex: 10, display: "inline-block", float: "left" }}>
            <Button
              onClick={() =>{
                  this.setState({driverOnGoingDeliveries: []});
                  this.drawMapLines(this.state.map, this.state.maps,this.getOrderLines())
                }
              }
            >
              Reset Pending Orders
            </Button>

            <Checkbox style={{ display: "block" }} defaultChecked={this.state.loadFixedDrivers} onChange={this.showFixedDrivers}>
              Show fixed drivers
            </Checkbox>
            <Checkbox style={{ display: "block", marginLeft: 0 }} defaultChecked={this.state.loadFreelanceDrivers} onChange={this.showFreelanceDrivers}>
              Show freelance drivers
            </Checkbox>
          </div>
          <div style={{ width: "100%", height: "800px" }}>
            <GoogleMapReact yesIWantToUseGoogleMapApiInternals onGoogleApiLoaded={({ map, maps }) => {
                this.drawMapLines(map, maps,this.getOrderLines());
                this.setState({ map });
                this.setState({ maps });
              }} bootstrapURLKeys={{ key: "AIzaSyBGOZAMmIsGDENkLlV0X-QJemy2cDSho_Q" }} defaultCenter={{ lat: 33.885426940041924, lng: 35.503411053015405 }} defaultZoom={14}>
              {this.state.orders_displayed.map((order) => {
                var fromPopup = { formattedAddress: order.fromFormattedAddress, userFullName: order.userFullName, userPhone: order.userPhone, id: order.id, deliveryCharge: order.deliveryCharge };
                return <OrderMarker key={order.id} title="Pickup" location={fromPopup} lat={order.fromLatitude} lng={order.fromLongitude} clicked={() => {
                      let newS={};
                      newS["fromLatitude"]=order.fromLatitude;
                      newS["fromLongitude"]=order.fromLongitude;
                      newS["toLatitude"]=order.toLatitude;
                      newS["toLongitude"]=order.toLongitude;
                      this.drawMapLines(this.state.map, this.state.maps,new Array(newS));
                    }} mouseEnter={() => {
                      this.loadSuggestedDrivers(order.id);
                    }} mouseLeave={this.resetSuggestedDrivers} />;
              })}
              {this.state.orders_displayed.map((order) => {
                var toPopup = { formattedAddress: order.toFormattedAddress, userFullName: order.userFullName, userPhone: order.userPhone, id: order.id, deliveryCharge: order.deliveryCharge };
                return <DestinationMarker key={order.id} title="Dropoff" location={toPopup} lat={order.toLatitude} lng={order.toLongitude} clicked={() => {
                      let newS={};
                      newS["fromLatitude"]=order.fromLatitude;
                      newS["fromLongitude"]=order.fromLongitude;
                      newS["toLatitude"]=order.toLatitude;
                      newS["toLongitude"]=order.toLongitude;
                      this.drawMapLines(this.state.map, this.state.maps,new Array(newS));
                    }} mouseEnter={() => {
                      this.loadSuggestedDrivers(order.id);
                    }} mouseLeave={this.resetSuggestedDrivers} />;
              })}

              {this.state.loadFixedDrivers ? this.state.drivers_fixed.map(
                    (driver) => {
                      var popup = { info: driver.msisdn };
                      return (
                        <DriverMarker
                          key={driver.id}
                          title={
                            driver.firstName +
                            " " +
                            driver.lastName +
                            ` [${this.state.onGoingDeliveries.length}]`
                          }
                          location={popup}
                          lat={driver.latitude}
                          lng={driver.longitude}
                          onHover={() =>
                            this.displayOnGoingOrders(driver.id)
                          }
                          onClick={() => {
                            this.toggleDriverOngoingDeliveires;
                          }}
                          onGoing={this.state.onGoingDeliveries}
                          driverStatus={driver.driverStatus}
                        />
                      );
                    }
                  ) : console.log(this.state.loadFixedDrivers)}
              {this.state.loadFreelanceDrivers ? this.state.drivers_freelance.map(
                    (driver) => {
                      var popup = { info: driver.msisdn };
                      return (
                        <DriverMarker
                          key={driver.id}
                          title={
                            driver.firstName +
                            " " +
                            driver.lastName +
                            ` [${this.state.onGoingDeliveries.length}]`
                          }
                          location={popup}
                          lat={driver.latitude}
                          lng={driver.longitude}
                          onHover={() =>
                            this.displayOnGoingOrders(driver.id)
                          }
                          onClick={() => {
                            this.toggleDriverOngoingDeliveires();
                          }}
                          onGoing={this.state.onGoingDeliveries}
                          driverStatus={driver.driverStatus}
                        />
                      );
                    }
                  ) : console.log(this.state.loadFixedDrivers)}
              {this.state.driverOnGoingDeliveries.map(
                  (delivery) => {
                    console.log(
                      "Inside drawDriverOrderMarkers",
                      delivery
                    );
                    var fromPopup = {
                      formattedAddress:
                        delivery.fromLocation.formattedAddress,
                      userFullName:
                        delivery.user.firstName +
                        " " +
                        delivery.user.lastName,
                      userPhone: delivery.user.phone,
                      id: delivery.fromLocation.id,
                      deliveryCharge: delivery.deliveryCharge,
                    };
                    return (
                      <OrderMarker
                        key={delivery.fromLocation.id}
                        title="Pickup"
                        location={fromPopup}
                        lat={delivery.fromLocation.latitude}
                        lng={delivery.fromLocation.longitude}
                        clicked={() => {
                          let newS={};
                          newS["fromLatitude"]=delivery.fromLocation.latitude;
                          newS["fromLongitude"]=delivery.fromLocation.longitude;
                          newS["toLatitude"]=delivery.toLocation.latitude;
                          newS["toLongitude"]=delivery.toLocation.longitude;
                          this.drawMapLines(this.state.map, this.state.maps,new Array(newS));
                        }}
                        mouseEnter={() => {
                          this.loadSuggestedDrivers(delivery.id);
                        }}
                        mouseLeave={this.resetSuggestedDrivers}
                      />
                    );
                  }
                )}
              {this.state.driverOnGoingDeliveries.map(
                  (delivery) => {
                    console.log(
                      "Inside drawDriverOrderMarkers",
                      delivery
                    );
                    var fromPopup = {
                      formattedAddress:
                        delivery.toLocation.formattedAddress,
                      userFullName:
                        delivery.user.firstName +
                        " " +
                        delivery.user.lastName,
                      userPhone: delivery.user.phone,
                      id: delivery.toLocation.id,
                      deliveryCharge: delivery.deliveryCharge,
                    };
                    return <DestinationMarker key={delivery.toLocation.id} title="Pickup" location={fromPopup} lat={delivery.toLocation.latitude} lng={delivery.toLocation.longitude} clicked={() => {
                          let newS={};
                          newS["fromLatitude"]=delivery.fromLocation.latitude;
                          newS["fromLongitude"]=delivery.fromLocation.longitude;
                          newS["toLatitude"]=delivery.toLocation.latitude;
                          newS["toLongitude"]=delivery.toLocation.longitude;
                          this.drawMapLines(this.state.map, this.state.maps,new Array(newS));  
                        }} mouseEnter={() => {
                          this.loadSuggestedDrivers(delivery.id);
                        }} mouseLeave={this.resetSuggestedDrivers} />;
                  }
                )}
            </GoogleMapReact>
          </div>
        </React.Fragment>;
    }
  }
}
