import {
    Table, Tag, message, Pagination,
  } from 'antd';
  import React from 'react';
  import { Helmet } from 'react-helmet';
  import Moment from 'react-moment';
  import moment from 'moment';
  import { Link } from '@reach/router';
  import API, { getAxiosInstance } from '../../api/api';
  import { PaginationProps } from 'antd/lib/pagination';
  import { DeliveryBean } from '../../api/deliveries/deliveryBean';
  import { UserBean } from '../../api/users/userBean';
  import { DriverBean } from '../../api/drivers/driver';
  import { LocationBean } from '../../api/locations/locationBean';
  
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      key: 'id',
      render: (title: string, ) => (title
        // <Link to={`/admin/book/${record.id}`}>{title}</Link>
      ),
    },
    {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'phone',
        dataIndex: 'phoneNumber',
        key: 'phone',
        render: (phone: string) => {
          return <a href={`tel:${phone}`}>{phone}</a>
        }
      },
      
    {
      title: 'Delivery',
      dataIndex: 'deliveryId',
      key: 'deliveryId',
    },
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'dateCreation',
      key: 'dateCreation',
      render: (date: string) => (
        <span>
          {/*
    // @ts-ignore */}
          {date ? <Moment title={moment(date).format('MMMM Do YYYY, h:mm:ss a')} fromNow>{date}</Moment> : '-'}
        </span>
      ),
    },
  
  ];
  
  
  interface DriversListProps {
    path: string,
    status?: number,
  }
  interface DriversListState {
    data?: [DriverBean],
    pagination: any,
    loading: boolean
  }
  
  class DriversList extends React.Component<DriversListProps, DriversListState> {
    state = {
      data: undefined,
      pagination: { pageSize: 10, total: 0, current: 1 },
      loading: false,
    };
  
  
    componentDidMount() {
      this.fetch({});
    }
  
    componentDidUpdate = (oldProps: DriversListProps) => {
      if (oldProps.status !== this.props.status) {
        this.fetch({});
      }
    }
  
    fetch = ({
      pageSize = 10, currentPage = 0, order = null, ascending = true,
    }) => {
      this.setState({ loading: true });
      getAxiosInstance().post(API.drivers_list, {
        page: currentPage - 1,
        size: pageSize,
        order,
        ascending,
        status: this.props.status,
  
  
      }).then((response) => {
        const pagination = { ...this.state.pagination };
        pagination.total = response.data.data.total;
        this.setState({ data: response.data.data.list, loading: false, pagination });
      }).catch((err) => {
        console.error(err);
        message.error('Something went wrong');
      });
    }
  
    handleTableChange = (pagination: any, filters: any, sorter: any) => {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
      });
      this.fetch({
        pageSize: pagination.pageSize,
        order: sorter.field,
        ascending: !(sorter.order === 'descend'),
        currentPage: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
  
    render() {
      const { loading, data, pagination } = this.state;
      {/* if usertype(usertype is saved from the log in session in localstorage) 4 dont show */}
      if(localStorage.getItem('userType') == "4"){
        return (
            <h3 style={{color: "#758790"}}>Nothing to see here...</h3>
        )
    }else{
        return (
        <React.Fragment>
          <Helmet>
            <title>DriversList List</title>
          </Helmet>
          <Table
           scroll={{ x: 1500}} 
            rowKey={(record: DriverBean) => record.id}
            loading={loading}
            dataSource={data}
            pagination={pagination}
            onChange={this.handleTableChange}
            columns={columns}
          // dataSource={data}
          />
        </React.Fragment>
      );
        }
    }
  }
  
  export default DriversList;
  