import React from 'react';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import 'ant-design-pro/dist/ant-design-pro.css'
import { Card, message } from 'antd';
import API, { getAxiosInstance } from '../../api/api';
import moment from 'moment';
import numeral from 'numeral';

export class ProfitToday extends React.Component {
    state = {
        loading: true,
        today:0,
        yesterday:0
    }
    componentDidMount = () => {
        this.setState({ loading: true });
        getAxiosInstance().post(API.profit_count, {
            from: moment().subtract(1, 'day'),
            to: moment().endOf('day'),
            criteria: 1

        }).then(({ data }) => {
            if (data.status) {
                const yesterday = data.data.list[0].total;
                const today = data.data.list[1].total;

                this.setState({ yesterday, today,loading:false });
            }
        }).catch((err) => {
            console.error(err);
            message.error('Something went wrong');
        });
    }
    render() {
        const { today, yesterday } = this.state;
        return (
            <Card loading={this.state.loading}>
                <NumberInfo
                    subTitle={<h3>Profit Today</h3>}
                    total={`${numeral(today).format('0,0.00')} $`}
                    status={today>yesterday?"up":"down"}
                    subTotal={numeral(Math.abs(yesterday-today)).format('0,0.00')}
                />
            </Card>
        )
    }
}